






































































import Vue from "vue";
import Layout from "@/router/layouts/main.vue";
export default Vue.extend({
  components: {
    Layout
  },
  created() {
    if (this.emails.length < 1) {
      this.$router.push("/emails");
    }
    let obj = this.emails.find(item => item.id == this.$route.params.id);
    if (obj) {
      this.editObj=JSON.parse(JSON.stringify(obj))
    } else {
      this.$router.push("/emails");
    }
  },
  data() {
    return {
      showModal: false,
      title: "Edit Email",
      editObj: {},

    };
  },
  computed:{
    name(): string{
      return this.$i18n.t("Title")
    },
    description(): string{
      return this.$i18n.t("Description")
    }

  },
  methods:{
    async updateMail(){
      let {id,...exclude}=this.editObj
      await this.updateEmail({id:id,data:exclude})
      this.fetchEmails({page:1})
      this.$router.push('/emails')
    },
    editorInit() {
      if(this.$route.params.lang=='English'){
        this.$refs.tmen.editor.setContent(this.editObj.emailContentEn);
      }
      else{

        this.$refs.tmsk.editor.setContent(this.editObj.emailContentSk);
      }
    },
  }
});
